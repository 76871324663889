import React, { useState, useEffect } from "react";
import imagen from "../assets/LOGO COMPLETO CCG NEGRO.png";
import Qr from "./Qr";
import "../css/menu.css";


function FacturaOtros({ ...props }) {
  document.oncontextmenu = function(){return false}
  const fecha = new Date();
  const [correlativo, setCorrelativo] = useState("");
  const [limite1, setlimite1] = useState("20/05/2023");
  const [limite2, setlimite2] = useState("29/06/2023");
  const [limite3, setlimite3] = useState("16/08/2023");
  const [limiteA, setlimiteA] = useState("");
  const [texto, settexto] = useState("Transferencia N°");

  const [rango1, setrango1] = useState(
    "000-003-01-00000001-000-003-01-00050000"
  );
  const [rango2, setrango2] = useState(
    "000-003-01-00050001-000-003-01-00100000"
  );

  const [rango3, setrango3] = useState(
    "000-003-01-00100001-000-003-01-00150000"
  );
  const [rangoA, setrangoA] = useState("");

  const [cai1, setcai1] = useState("9E41AF-EE62C8-E34D9D-2F6D59-5CF268-E8");
  const [cai2, setcai2] = useState("11F6C7-8E7304-5A4CB3-9482AB-77651B-A5");
  const [cai3, setcai3] = useState("4C5864-0B43DC-5D48A2-9E6858-1D494E-69");
  const [caiA, setcaiA] = useState("");
  const [ocultar, setOcultar] = useState("none");

  let mh = "none";
  const [mostrarHE, setMostrarHE] = useState("none");

  const recargar = () => {
    const setMostrarF = props.v[0];
    setMostrarF("none");
    const mrtn = props.v[1];
    mrtn("none");
    const total = props.v[2];
    total(0);
    const h = props.v[3];
    h("");
    const setTipoV = props.v[4];
    setTipoV("");
    const setTipoC = props.v[5];
    setTipoC("");
    const setNombre = props.v[6];
    setNombre("");
    const setRTN = props.v[7];
    setRTN("");
    const setRecibido = props.v[8];
    setRecibido(0);
    const setCambio = props.v[9];
    setCambio(0);
    const setNFactura = props.v[10];
    setNFactura("");
    const setPrincipal = props.v[11];
    setPrincipal("");
    props.setMostrarLista("");
    props.setColor("white");
    props.setNombreP("")
    props.setMarca("") 
    props.setModelo("")
    props.setPlaca("")
    props.setTelefono("")
    props.setpago("Efectivo")
    props.setbanco("")
    props.settransferencia("")
    props.setmTransferencia("none")
    props.setcheck1(true)
    props.setcheck2(false)
    props.setcheck3(false)
  };

  useEffect(() => {
    if (props.tipoC === "Otro") {
      setOcultar("");
    } else if (props.tipoC === "Consumidor Final") {
      setOcultar("none");
    }
  }, [props.tipoC]);

  useEffect(() => {
    if (props.pago === "Efectivo" || props.pago === "Transferencia") {
      settexto("Transferencia N°");
    } else if (props.pago === "Tarjeta") {
      settexto("Comprobante Pago N°");
    }
  }, [props.pago]);



  useEffect(() => {
     if(parseInt(props.nFactura) <= parseInt(910000)){
      setlimiteA("15/07/2025");
      setrangoA("000-003-01-00810001 / 000-003-01-00910000");
      setcaiA("1D521A-AFF6C3-494AE0-63BE03-090965-14");
      setCorrelativo("000-003-01-00");  
    }else if(parseInt(props.nFactura) <= parseInt(1010000)){
      setlimiteA("11/06/2025");
      setrangoA("000-003-01-00910001 / 000-003-01-01010000");
      setcaiA("21E258-281E55-9F41E0-63BE03-0909AB-8C");
      if(props.nFactura>999999){
        setCorrelativo("000-003-01-0");   
      }else{
        setCorrelativo("000-003-01-00"); 
      }
    }else{
      setlimiteA("10/01/2026");
      setrangoA("000-003-01-01010001 / 000-003-01-01110000");
      setcaiA("2B5E44-B25209-D88DE0-63BE03-0909DF-D7");
      setCorrelativo("000-003-01-0"); 
    }
  }, [props.nFactura]);

  

  let tipoV = "";
  if (props.tipoV === "Parqueo x Hora Vehículo") {
    tipoV = "Parqueo x Hora Vehículo";
    mh = "";
  } else if (props.tipoV === "Parqueo x Hora Motocicleta") {
    tipoV = "Parqueo x Hora Motocicleta";
    mh = "";
  } else {
    tipoV = "Extavío de Ticket";
    mh = "none";
  }

  return (
    <React.Fragment>
      <div className='ContenedorMostrarF'>
        <section>
          <h1>Centro Civíco</h1>
          <h1>Gubernamental</h1>
          <img src={imagen} className='imagen' alt='Logo' onClick={recargar} />
        </section>

        <section className='contenedorDatosFactura'>
          <h2 style={{ marginTop: "1.5rem" }}>
            <b>
              {fecha.getDate() +
                "/" +
                (fecha.getMonth() + parseInt(1)) +
                "/" +
                fecha.getFullYear()}
            </b>
          </h2>

          <label className='labelDatosFactura'>
            <b>Concepto: Pago de Estacionamiento</b>
          </label>
          <label className='labelDatosFactura'>Auto Impresión</label>
          <label className='labelDatosFactura'>CAI:</label>
          <label className='labelDatosFacturac'>{caiA}</label>
          <label className='labelDatosFactura'>DOIH, SA DE CV</label>
          <label className='labelDatosFactura'>RTN: {props.rtn2}</label>
          <label className='labelDatosFactura'>Rango de Impresión:</label>
          <label className='labelDatosFacturac'>
          {rangoA}
          </label>
          <label className='labelDatosFactura'>
            Límite de Emisión: {limiteA}
          </label>
          <label className='labelDatosFacturac'>
            Factura Original N°:{correlativo + props.nFactura}
          </label>
        </section>

        <section className='contenedorInfoFactura'>
          <label style={{ fontSize: "2rem", width: "auto" }}>
            Cliente: {props.tipoC}
          </label>
          <label style={{ fontSize: "1.8rem", display: `${ocultar}` }}>
            RTN: <span style={{ fontSize: "1.6rem" }}>{props.rtn}</span>
          </label>
          <label
            style={{ fontSize: "1.8rem", width: "auto", display: `${ocultar}` }}
          >
            Nombre: {props.nombre}
          </label>
          <label style={{ fontSize: "2rem", marginTop: "1rem", width: "auto" }}>
            Servicio:
            <span style={{ fontSize: "2rem" }}>{tipoV}</span>
          </label>
          <label style={{ fontSize: "2rem", display: `${mh}` }}>
            <b>Hora Entrada: {props.hora_Entrada + ":" + "00"} </b>
          </label>
          <label style={{ fontSize: "2rem" }}>
            <b>Hora Salida: {props.h} </b>
          </label>

            <label style={{ fontSize:"2rem", width: "auto",display:`${props.mostrarMas}` }}>
              Tipo Vehículo: {props.tipoV}
              
            </label>
            <label style={{ fontSize: "2rem", width: "auto",display:`${props.mostrarMas}` }}>
              Nombre: {props.nombreP}

            </label>
            <label style={{ fontSize: "2rem", width: "auto",display:`${props.mostrarMas}`}}>
              Marca: {props.marca}
         
            </label>
            <label style={{ fontSize: "2rem", width: "auto",display:`${props.mostrarMas}` }}>
              Modelo: {props.modelo}
          
            </label>
            <label style={{ fontSize: "2rem", width: "auto",display:`${props.mostrarMas}` }}>
              Placa: {props.placa}
       
            </label>
            <label style={{ fontSize: "2rem", width: "auto",display:`${props.mostrarMas}` }}>
              Teléfono: {props.telefono}
             
            </label>
      

          <label style={{ fontSize: "2rem" }}>
            Subtotal: {`LPS.${parseFloat(props.total / 1.15).toFixed(2)}`}
          </label>
          <label style={{ fontSize: "2rem" }}>
            ISV:
            {` LPS.${(
              parseFloat(props.total) - parseFloat(props.total / 1.15)
            ).toFixed(2)}`}
          </label>
          
          <label style={{ fontSize: "2rem" }}>
            Total:{` LPS.${props.total}`}{" "}
          </label>

          <label style={{ fontSize: "2rem" }}>
            Forma de Pago: {props.pago}
          </label>
          <label style={{ fontSize: "2rem",display:`${props.mTransferencia}`}}>
            Banco: {props.banco}
          </label>
          <label style={{ fontSize: "2rem",display:`${props.mTransferencia}` }}>
             {texto}: {props.transferencia}
          </label>

          <label style={{ fontSize: "2rem" }}>
            Recibido: {`LPS.${props.recibido}`}
          </label>
          <label style={{ fontSize: "2rem" }}>
            Cambio:{" "}
            {`LPS.${parseInt(props.recibido) - parseInt(props.total)}.00`}
          </label>
        </section>
        <section style={{ margin: "1rem 0" }}>
          <Qr
            value={`Fecha de Factura: ${  fecha.getDate()<10?"0"+fecha.getDate()+"/" +(fecha.getMonth() + parseInt(1)) +"/" +fecha.getFullYear():fecha.getDate()+"/" +(fecha.getMonth() + parseInt(1)) +"/" +fecha.getFullYear()}. 
Hora que Facturo: ${props.h}.
Servicio:${tipoV}.
`}
          ></Qr>
        </section>
        <b>
          <p
            style={{
              width: "35rem",
              fontSize: "1.2rem",
              textAlign: "start",
              fontFamily: "PT Serif, serif",
            }}
          >
            Dirección: Blvd. Juan Pablo Segundo esquina con República de Corea
            Tegucigalpa, M.D.C. Honduras C.A. Teléfono: +5042243-7241. Correo
            electrónico: Ives.jimenez@doih.hn
          </p>{" "}
        </b>
      </div>
    </React.Fragment>
  );
}

export default FacturaOtros;
